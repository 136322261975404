/* eslint-disable no-console */
/* global Paho, SigV4Utils */
import AWS from 'aws-sdk';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  ICognitoUserPoolData,
  AuthenticationDetails,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import ApiService from 'services/api';
import { makeId, sleep } from 'utils/utils';
import { StateSlice, Store } from '../type';
import { cleanupStore } from '../index';

const stackConfig = {
  up: process.env.REACT_APP_USERPOOL,
  client: process.env.REACT_APP_APPCLIENT,
  ip: process.env.REACT_APP_IDENTITYPOOL,
  poolregion: process.env.REACT_APP_POOLREGION,
};
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: stackConfig.ip,
});
const poolData: ICognitoUserPoolData = {
  UserPoolId: stackConfig.up,
  ClientId: stackConfig.client,
};
const userPool = new CognitoUserPool(poolData);

export type AuthSlice = {
  cognitoid?: string;
  cognitoUser?: CognitoUser;
  progress?: number;
  balance?: number;
  tempCredentials?: {
    email: string;
    password: string;
  };
  isAuthenticated?: boolean;
  resetPassword(verificationCode: string, newPassword): Promise<boolean>;
  forgotPassword(email: string): Promise<any>;
  getUserData(): Promise<any>;
  getUserAttributes(): Promise<any>;
  resendConfirmationCode(): Promise<any>;
  login(email: string, password: string): Promise<void>;
  confirmRegistration(code: string): Promise<void>;
  getBalance(): Promise<void>;
  handleMQTT(): Promise<void>;
  getAWSCredentials(token: any): Promise<void>;
  logout(): Promise<void>;
  handleMqttFailure(error: any): Promise<void>;
  refreshCognito(): void;
  restoreUserFromStorage(): Promise<boolean>;
  updateCredentials(): void;
  signUp({
    firstName,
    lastName,
    email,
    password,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }): Promise<void>;
};
export const createAuthSlice: StateSlice<Store, AuthSlice> = (set, get) => ({
  async signUp({
    firstName,
    lastName,
    email,
    password,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) {
    const givenName = {
      Name: 'given_name',
      Value: firstName,
    };

    const familyName = {
      Name: 'family_name',
      Value: lastName,
    };

    const dataEmail = {
      Name: 'email',
      Value: email,
    };

    const attributeGivenName = new CognitoUserAttribute(givenName);
    const attributeFamilyName = new CognitoUserAttribute(familyName);
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeList: any = [];
    attributeList.push(attributeGivenName);
    attributeList.push(attributeFamilyName);
    attributeList.push(attributeEmail);

    const user = await new Promise((resolve, reject) => {
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        return err ? reject(err) : resolve(result.user);
      });
    });
    set({
      cognitoUser: user as any,
      tempCredentials: {
        email,
        password,
      },
    });
  },
  async handleMQTT() {
    const topic = `ai/${get().cognitoid}/info`;
    const requestUrl = SigV4Utils.getSignedUrl(
      'wss',
      'a3lvh6w4b5yz3-ats.iot.us-west-2.amazonaws.com',
      '/mqtt',
      'iotdevicegateway',
      'us-west-2',
      AWS.config.credentials.accessKeyId,
      AWS.config.credentials.secretAccessKey,
      AWS.config.credentials.sessionToken
    );

    const client = new Paho.Client(requestUrl, makeId(20));
    const connectOptions = {
      onSuccess() {
        console.log('connected mqtt');
        client.subscribe(topic);
      },
      useSSL: true,
      timeout: 10,
      mqttVersion: 4,
      keepAliveInterval: 1200, // max 1200 on AWS IoT
      reconnect: true,
      onFailure: get().handleMqttFailure,
    };

    client.connect(connectOptions);
    client.onMessageArrived = (message) => {
      const msg = JSON.parse(message.payloadString);
      const status = `${msg.msg}`;
      if (status === 'status') {
        get().updateJobState(msg.jobid, msg.state);
      }
    };
  },
  restoreUserFromStorage() {
    const cognitoUser = userPool.getCurrentUser();
    set({
      cognitoUser,
    });
    if (cognitoUser) {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession((err1, session) => {
          if (err1) {
            reject(err1);
          } else {
            console.log(`session validity: ${session.isValid()}`);
            get().getAWSCredentials(session);
            resolve(true);
          }
        });
      });
    }
    console.log('no currentUser');
    return Promise.resolve(null);
  },
  async handleMqttFailure(error: any) {
    console.error(`connect failed ${error.errorMessage}`);
    const { handleMQTT, cognitoid } = get();
    try {
      if (cognitoid) {
        await ApiService.instance.recheckMqttAuth(cognitoid);
        await sleep(1000);
        await handleMQTT();
      }
    } catch (err) {
      console.error(err);
    }
  },
  updateCredentials() {
    (AWS.config.credentials as any).get((err): void => {
      if (err) {
        get().updateCredentials();
        return;
      }
      set({
        cognitoid: (AWS.config.credentials as any).identityId,
      });
      get().getBalance();
      get().handleMQTT();
    });
  },
  async getBalance() {
    const data: any = await ApiService.instance.getBalance(get().cognitoid);
    set({ balance: data.balance });
  },
  async getAWSCredentials(tokens) {
    const accessToken = tokens.getIdToken().getJwtToken();
    const { cognitoid, cognitoUser, updateCredentials } = get();

    // creates a new cognitoid
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: stackConfig.ip,
        IdentityId: cognitoid, // b0b0 - if not provided, a new identity is created every time
        Logins: {
          [`cognito-idp.${stackConfig.poolregion}.amazonaws.com/${stackConfig.up}`]: accessToken,
        },
      },
      {
        region: stackConfig.poolregion,
      }
    );

    cognitoUser?.getUserAttributes((err2, attributes) => {
      if (!err2) {
        set({
          isAuthenticated: true,
        });
      }
    });

    (AWS.config.credentials as any).get((err) => {
      if (err) {
        console.log(err);
      } else {
        updateCredentials();
      }
    });
  },
  refreshCognito() {
    const { cognitoUser } = get();
    if (!cognitoUser) return;

    cognitoUser.getSession((_err, session) => {
      const refreshToken = session.getRefreshToken();
      function updateSession() {
        cognitoUser.refreshSession(refreshToken, (err, newSession) => {
          if (err) {
            console.log(err);
          } else {
            const token = newSession.getIdToken().getJwtToken();
            (AWS.config.credentials as any).params.Logins[
              `cognito-idp.${stackConfig.poolregion}.amazonaws.com/${stackConfig.up}`
            ] = token;
            (AWS.config as any).jwt = token;
            (AWS.config.credentials as any).refresh((error) => {
              if (error) {
                console.log(error);
              } else {
                console.log('TOKEN SUCCESSFULLY UPDATED');
              }
            });
          }
        });
      }
      updateSession();
    });
  },
  async login(email: string, password: string) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    const data = await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result) {
          resolve(result);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });

    set({
      tempCredentials: {
        email,
        password,
      },
      cognitoUser,
    });
    get().getAWSCredentials(data);
  },
  async confirmRegistration(code: string) {
    const { tempCredentials, cognitoUser, login } = get();
    await new Promise((resolve, reject) => {
      cognitoUser?.confirmRegistration(code, true, (err, result) => {
        return err ? reject(err) : resolve(result);
      });
    });
    const { email, password } = tempCredentials;
    await login(email, password);
  },
  resendConfirmationCode() {
    return new Promise((resolve, reject) => {
      get().cognitoUser?.resendConfirmationCode((err, result) => {
        return err ? reject(err) : resolve(result);
      });
    });
  },
  getUserAttributes() {
    return new Promise((resolve, reject) => {
      get().cognitoUser?.getUserAttributes((err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    });
  },
  getUserData() {
    return new Promise((resolve, reject) => {
      get().cognitoUser?.getUserData((err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    });
  },
  async logout() {
    set({
      tempCredentials: undefined,
      isAuthenticated: false,
    });

    const { cognitoUser } = get();
    if (cognitoUser) {
      cognitoUser.signOut();
      window.location.reload();
    }

    cleanupStore();
  },
  async forgotPassword(email: string) {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess(data) {
          resolve(data);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  },
  async resetPassword(verificationCode: string, newPassword) {
    return new Promise((resolve, reject) => {
      get().cognitoUser?.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve(true);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  },
});
