import { useState } from 'react';
import { useConcepts } from 'services/react-query/queries';

const useConceptsInterval = (cognitoid: string, options: any) => {
  const [stop, setStop] = useState(false);
  const { isFetching, data, refetch } = useConcepts(cognitoid, {
    onSuccess: (d) => {
      if (d.status === 'finished') {
        setStop(true);
      }
    },
    onError: (error) => {
      setStop(true);
    },
    enabled: !!cognitoid,
    refetchInterval: stop ? false : 60 * 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    ...options,
  });

  return { isFetching, data, refetch };
};

export default useConceptsInterval;
