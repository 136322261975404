import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import Home from 'pages/home';
import CreateArt from 'pages/create-art';
import Layout from 'containers/layout';
import { GlobalProvider } from 'contexts/global-context';
import DeviceProvider from 'contexts/responsive-context';
import FAQ from 'pages/faq';
import Concept from 'pages/concept';
import Queue from 'pages/queue';
import Profile from 'pages/profile';
import Credits from 'pages/credits';
import MyArt from 'pages/my-art';
import { useStore } from './store/index';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPETOKEN);

const queryClient = new QueryClient();

function App() {
  const restoreUserFromStorage = useStore((state) => state.restoreUserFromStorage);
  const updateCredentials = useStore((state) => state.updateCredentials);
  const [isBootstrap, setIsBootstrap] = useState(true);

  useEffect(() => {
    restoreUserFromStorage().then(() => {
      setIsBootstrap(false);
    });
    updateCredentials();
  }, []);

  if (isBootstrap) {
    return (
      <DeviceProvider>
        <Layout>
          <p>Loading...</p>
          <ToastContainer />
        </Layout>
      </DeviceProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <DeviceProvider>
        <GlobalProvider>
          <Elements stripe={stripePromise}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/create-art" element={<CreateArt />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/queue" element={<Queue />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/credits" element={<Credits />} />
              <Route path="/my-art" element={<MyArt />} />
              <Route path="/concept" element={<Concept />} />
              <Route path="*" element={<Home />} />
            </Routes>
            <ToastContainer />
          </Elements>
        </GlobalProvider>
      </DeviceProvider>
    </QueryClientProvider>
  );
}

export default App;
