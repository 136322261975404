import omit from 'lodash-es/omit';
import { create } from 'zustand';
import { createJSONStorage, persist, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Store } from './type';
import { createAppSlice } from './slices/app';
import { createAuthSlice } from './slices/auth';
import { createArtSlice } from './slices/art';

export const useStore = create<
  Store,
  [['zustand/devtools', never], ['zustand/persist', any], ['zustand/immer', never]]
>(
  devtools(
    persist(
      immer((...args) => ({
        ...createAppSlice(...args),
        ...createArtSlice(...args),
        ...createAuthSlice(...args),
      })),
      {
        name: 'nb_store',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => omit(state, ['cognitoUser']),
      }
    )
  )
);

const { destroy: destroyStore } = useStore;

export function cleanupStore() {
  destroyStore();
}
