import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import prop-types

const ImageDisplay = ({ imageUrl, retryInterval, timeout }) => {
    const [imageExists, setImageExists] = useState(true);


    useEffect(() => {
	let timeoutId;

	const checkImage = () => {
	    const img = new Image();
	    img.src = imageUrl;
	    img.onload = () => {
		console.log("GOT THE IMAGE!");
		setImageExists(true);
		clearTimeout(timeoutId);
	    };
	    img.onerror = () => {
		setImageExists(false);
		timeoutId = setTimeout(checkImage, retryInterval);
	    };
	};

	checkImage();

	return () => {
	    clearTimeout(timeoutId);
	};
    }, [imageUrl, retryInterval, timeout]);

    return (
	<div>
	    {imageExists ? (
		<img src={imageUrl} alt="Artwork" />
	    ) : (
		<p>Image not found. Please try again later.</p>
	    )}
	</div>
    );
};

// Define prop types
ImageDisplay.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    retryInterval: PropTypes.number.isRequired,
    timeout: PropTypes.number.isRequired,
};

export default ImageDisplay;
