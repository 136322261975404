export const icons = {
  arrow_down: '/assets/icons/arrow_down.svg',
  btn_copy: '/assets/icons/btn_copy.svg',
  btn_fb: '/assets/icons/btn_fb.svg',
  btn_git: '/assets/icons/btn_git.svg',
  btn_google: '/assets/icons/btn_google.svg',
  btn_twitter: '/assets/icons/btn_twitter.svg',
  clock: '/assets/icons/clock.svg',
  close: '/assets/icons/close.svg',
  download: '/assets/icons/download.svg',
  discord: '/assets/icons/discord.svg',
  email_sent: '/assets/icons/email_sent.svg',
  filter: '/assets/icons/filter.svg',
  edit: '/assets/icons/edit.svg',
  google_pay: '/assets/icons/google_pay.svg',
  heart: '/assets/icons/heart.svg',
  info: '/assets/icons/info.svg',
  layout_four: '/assets/icons/layout_four.svg',
  layout_two: '/assets/icons/layout_two.svg',
  logo_text: '/assets/icons/logo_text.svg',
  logo: '/assets/icons/logo.svg',
  paypal: '/assets/icons/paypal.svg',
  mail: '/assets/icons/mail.svg',
  refresh: '/assets/icons/refresh.svg',
  share: '/assets/icons/share.svg',
  stripe: '/assets/icons/stripe.svg',
  twitter: '/assets/icons/twitter.svg',
};
