/* eslint-disable class-methods-use-this */
/* eslint-disable no-use-before-define */
import AWS from 'aws-sdk';
import aws4 from 'aws4';

class AwsService {
  private static sInstance: AwsService;

  public static get instance(): AwsService {
    if (!AwsService.sInstance) {
      AwsService.sInstance = new AwsService();
    }

    return AwsService.sInstance;
  }

  getSingedRequestForJob({
    stackurl,
    cognitoid,
    resume,
  }: {
    stackurl: string;
    cognitoid: string;
    resume: string;
  }) {
    let resumestring = '';
    if (resume) {
      resumestring = `&resume=${resume}`;
    }
    const request = {
      url: `${stackurl}jobs?cognitoid=${String(cognitoid)}${resumestring}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    const signedRequest = aws4.sign(request, {
      secretAccessKey: AWS.config.credentials.secretAccessKey,
      accessKeyId: AWS.config.credentials.accessKeyId,
      sessionToken: AWS.config.credentials.sessionToken,
    });

    delete signedRequest.headers.Host;
    delete signedRequest.headers['Content-Length'];

    return signedRequest;
  }

  getSingedRequestForBalance({ stackurl, cognitoid }: { stackurl: string; cognitoid: string }) {
    const request = {
      url: `${stackurl}balance?cognitoid=${String(cognitoid)}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    const signedRequest = aws4.sign(request, {
      secretAccessKey: AWS.config.credentials.secretAccessKey,
      accessKeyId: AWS.config.credentials.accessKeyId,
      sessionToken: AWS.config.credentials.sessionToken,
    });

    delete signedRequest.headers.Host;
    delete signedRequest.headers['Content-Length'];

    return signedRequest;
  }
}

export default AwsService;
