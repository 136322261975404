/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import { formatDistance, fromUnixTime, startOfDay, endOfDay } from 'date-fns';
import { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { isBetween } from 'utils/time';
import Layout from 'containers/layout';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as LayoutTwoIcon } from 'assets/icons/layout-two.svg';
import { ReactComponent as LayoutFourIcon } from 'assets/icons/layout-four.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { getArtInfo } from 'utils/data';
import FilterDropdown from 'components/filter-dropdown';
import MobileFilter from 'components/mobile-filter';
import { useStore } from '../store/index';

const Queue = () => {
  const getJobs = useStore((state) => state.getJobs);
  const jobs = useStore((state) => state.jobs);
  const jobDict = useStore((state) => state.jobDict);
  const queueFilters = useStore((state) => state.queueFilters);
  const updateQueueFilters = useStore((state) => state.updateQueueFilters);

  const [arts, setArts] = useState([]);
  const [layoutPreset, setLayoutPreset] = useState('layout-two');
  const [filterActive, setFilterActive] = useState(false);
  const [styles, setStyles] = useState(queueFilters.styles);
  const [date, setDate] = useState(queueFilters.date);

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    const filteredArts = jobs.filter((job) => {
      const hyperion = queueFilters.styles?.hyperion;
      const crynos = queueFilters.styles?.crynos;
      const rhea = queueFilters.styles?.rhea;
      const dallemega = queueFilters.styles?.dallemega;
      const createdFrom = queueFilters.date?.from;
      const createdTo = queueFilters.date?.to;
      const art = getArtInfo(job);

      let valid = true;
      if (createdFrom && createdTo) {
        if (art.info?.createdAt !== undefined) {
          const isBelongRange = isBetween(
            fromUnixTime(art.info.createdAt / 1000),
            startOfDay(new Date(createdFrom)),
            endOfDay(new Date(createdTo))
          );
          if (!isBelongRange) {
            valid = false;
          }
        }
      }

      if (!hyperion || !crynos || !rhea || !dallemega) {
        if (
          (hyperion && art.job?.core !== 'vqganclip') ||
          (rhea && art.job?.core !== 'rhea') ||
          (dallemega && art.job?.core !== 'dallemega') ||
          (crynos && art.job?.core !== 'diffclip')
        ) {
          valid = false;
        }
      }

      return valid;
    });

    setArts(filteredArts);
  }, [jobs, queueFilters]);

  const renderJobs = () => {
    if (arts?.length) {
      const artsComponents = arts.reduce((result, job) => {
        if (job.jobopen) {
          const art = getArtInfo(job);
          if (jobDict[job.jobid] && jobDict[job.jobid] === 'finished') {
            result.push(
              <div
                key={job.jobid}
                className={`flex rounded-xl ${
                  layoutPreset === 'layout-two' && 'bg-mirage px-4 py-6'
                }`}
              >
                <img
                  className={`rounded-xl ${
                    layoutPreset === 'layout-four' ? 'h-[9.5rem] w-full' : 'h-[9.5rem] w-[9.5rem]'
                  }`}
                  src={art.info.url}
                  alt="art"
                />
                <div className={`ml-5 text-xs ${layoutPreset === 'layout-four' && 'hidden'}`}>
                  <p className="text-bold text-base">{art.info.name}</p>
                  <div className="flex items-center text-lightGray">
                    <ClockIcon className="mr-1 inline-block h-3 w-3" />
                    <span>
                      {formatDistance(parseInt(art.info.createdAt, 10), new Date(), {
                        addSuffix: true,
                      })}
                    </span>
                  </div>
                  <p className="mt-3">{art.info.ai}</p>
                  <p>{art.info.canvasSize}</p>
                  <p>{art.info.resolution}</p>
                  {art.info.seed !== null && art.info.seed !== undefined && (
                    <p>Seed: {art.info.seed}</p>
                  )}
                  <p>
                    {art.info.tier === 'free' ? (
                      'Free Tier'
                    ) : (
                      <>
                        Premium{' '}
                        <span className="text-link">
                          {art.info.creditsRequired ? `(${art.info.creditsRequired} CREDITS)` : ''}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            );
          } else {
            result.push(
              <div
                key={job.jobid}
                className={`flex rounded-xl ${
                  layoutPreset === 'layout-two' && 'bg-mirage px-4 py-6'
                }`}
              >
                <div
                  className={`flex items-center justify-center rounded-xl bg-mischka ${
                    layoutPreset === 'layout-four' ? 'h-[9.5rem] w-full' : 'h-[9.5rem] w-[9.5rem]'
                  }`}
                >
                  <ClipLoader loading size={30} />
                </div>
                <div className={`ml-5 text-xs ${layoutPreset === 'layout-four' && 'hidden'}`}>
                  <p className="text-bold text-base">{art.info.name}</p>
                  <div className="flex items-center text-lightGray">
                    <ClockIcon className="mr-1 inline-block h-3 w-3" />
                    <span>
                      {formatDistance(parseInt(art.info.createdAt, 10), new Date(), {
                        addSuffix: true,
                      })}
                    </span>
                  </div>
                  <p className="mt-3">{art.info.ai}</p>
                  <p>{art.info.canvasSize}</p>
                  <p>{art.info.resolution}</p>
                  {art.info.seed !== null && art.info.seed !== undefined && (
                    <p>Seed: {art.info.seed}</p>
                  )}
                  <p>
                    {art.info.tier === 'free' ? (
                      'Free Tier'
                    ) : (
                      <>
                        Premium{' '}
                        <span className="text-link">
                          {art.info.creditsRequired ? `(${art.info.creditsRequired} CREDITS)` : ''}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            );
          }
        }

        return result;
      }, []);
      if (artsComponents.length) {
        return (
          <div
            className={`mt-10 grid gap-y-8 gap-x-6 ${
              layoutPreset === 'layout-four' ? 'grid-cols-2' : ''
            }`}
          >
            {artsComponents}
          </div>
        );
      }
    }

    return (
      <div className="mt-10 text-center">
        <p>Queue is empty. Blend something or check &apos;my art&apos;.</p>
      </div>
    );
  };

  const handleClearFilter = () => {
    setDate({
      from: null,
      to: null,
    });
    setStyles({
      hyperion: false,
      crynos: false,
      rhea: false,
      dallemega: false,
    });
    updateQueueFilters({
      styles: {
        hyperion: false,
        crynos: false,
        rhea: false,
        dallemega: false,
      },
      date: {
        from: null,
        to: null,
      },
    });

    toast.success('Filters cleared successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleToggleFilters = () => {
    if (!filterActive) {
      setFilterActive(true);
    } else {
      setStyles(queueFilters.styles);
      setDate(queueFilters.date);
      setFilterActive(false);
    }
  };

  const handleApplyFilter = () => {
    updateQueueFilters({
      styles,
      date,
    });
    setFilterActive(false);
  };

  const handleRefreshClick = async () => {
    try {
      await getJobs();
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout>
      <div className={filterActive ? 'hidden' : ''}>
        <div className="absolute h-[20rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:h-[25rem] md:bg-headerBg md:bg-contain md:bg-right" />
        <div className="container md:h-[35rem] md:pt-40">
          <section className="relative z-50 px-4 pb-14 pt-40 md:px-10 md:pt-20 md:pb-20">
            <div className="text-center md:w-1/2 md:text-left">
              <h1 className="text-4xl font-bold">Creation in progress</h1>
              <p className="mt-5 px-20 text-lg leading-5 md:px-0">
                The creation queue may take a while.
                <br />
                Meanwhile please check out our{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                  href="https://discord.com/invite/3eqCqN493J"
                >
                  Discord community
                </a>
              </p>
            </div>
          </section>
        </div>
        <div className="container">
          <section className="px-8 pt-8 pb-16">
            <div className="flex justify-between">
              <div>
                <button className="mr-4" onClick={() => setLayoutPreset('layout-four')}>
                  <svg width="0" height="0">
                    <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                      <stop offset="0" />
                      <stop offset="1" />
                    </linearGradient>
                    <symbol id="border" overflow="visible">
                      <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                    </symbol>
                  </svg>
                  <div className="relative flex h-10 w-10 items-center justify-center">
                    <svg className={`module-border ${layoutPreset !== 'layout-four' && 'hidden'}`}>
                      <use href="#border" />
                    </svg>
                    <LayoutFourIcon className="h-8 w-8" />
                  </div>
                </button>
                <button className="mr-5" onClick={() => setLayoutPreset('layout-two')}>
                  <svg width="0" height="0">
                    <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                      <stop offset="0" />
                      <stop offset="1" />
                    </linearGradient>
                    <symbol id="border" overflow="visible">
                      <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                    </symbol>
                  </svg>
                  <div className="relative flex h-10 w-10 items-center justify-center">
                    <svg className={`module-border ${layoutPreset !== 'layout-two' && 'hidden'}`}>
                      <use href="#border" />
                    </svg>
                    <LayoutTwoIcon className="h-8 w-8" />
                  </div>
                </button>
                <button onClick={handleRefreshClick}>
                  <RefreshIcon />
                </button>
              </div>
              <div className="md:hidden">
                <button onClick={() => setFilterActive(true)}>
                  <svg width="0" height="0">
                    <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                      <stop offset="0" />
                      <stop offset="1" />
                    </linearGradient>
                    <symbol id="border" overflow="visible">
                      <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                    </symbol>
                  </svg>
                  <div className="relative flex h-10 w-10 items-center justify-center">
                    <svg className="module-border">
                      <use href="#border" />
                    </svg>
                    <FilterIcon className="h-6 w-6" />
                  </div>
                </button>
              </div>
              <div className="hidden md:inline-block">
                <FilterDropdown
                  styles={styles}
                  setStyles={setStyles}
                  date={date}
                  setDate={setDate}
                  handleApplyFilter={handleApplyFilter}
                  handleClearFilter={handleClearFilter}
                />
              </div>
            </div>

            {renderJobs()}
          </section>
        </div>
      </div>
      <MobileFilter
        open={filterActive}
        handleToggleFilters={handleToggleFilters}
        styles={styles}
        setStyles={setStyles}
        date={date}
        setDate={setDate}
        handleApplyFilter={handleApplyFilter}
        handleClearFilter={handleClearFilter}
      />
    </Layout>
  );
};

export default Queue;
