/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance } from 'axios';
import {
  CreateConceptParams,
  CreateConceptResponse,
  StartProceedingConceptParams,
} from 'services/api/types';
import AwsService from 'services/aws';

class ApiService {
  private static _instance: ApiService;
  gateway: AxiosInstance;

  constructor() {
    this.gateway = axios.create({
      timeout: 60000,
      headers: { 'Content-Type': 'application/json' },
    });

    this.gateway.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public static get instance(): ApiService {
    if (!ApiService._instance) {
      ApiService._instance = new ApiService();
    }

    return ApiService._instance;
  }

  async createArt(data) {
    return this.gateway.post(`${process.env.REACT_APP_STACKURL}jobs`, JSON.stringify(data));
  }

  async getJobs(data: { cognitoid: string; resume: string | null }) {
    const signedRequest: any = AwsService.instance.getSingedRequestForJob({
      stackurl: process.env.REACT_APP_STACKURL,
      ...data,
    });
    return this.gateway.request(signedRequest);
  }

  async pay(data: { cognitoid: string; type: string; amount: string; token: string }) {
    return this.gateway.post(`${process.env.REACT_APP_STACKURL}pay`, JSON.stringify(data));
  }

  async getBalance(cognitoid: string) {
    const signedRequest: any = AwsService.instance.getSingedRequestForBalance({
      stackurl: process.env.REACT_APP_STACKURL,
      cognitoid,
    });
    return this.gateway.request(signedRequest);
  }

  // eslint-disable-next-line class-methods-use-this
  async recheckMqttAuth(cognitoid: string) {
    return axios.get(process.env.REACT_APP_MQTT_AUTH, {
      params: {
        cognitoid,
      },
    });
  }

  async createConcept(data: CreateConceptParams) {
    return this.gateway.post<any, CreateConceptResponse>(
      'https://qs7buxp8n2.execute-api.us-west-2.amazonaws.com/production/concepts',
      data
    );
  }

  async startProceedingConcept(data: StartProceedingConceptParams) {
    return this.gateway.put<any, any>(
      'https://qs7buxp8n2.execute-api.us-west-2.amazonaws.com/production/concepts',
      data
    );
  }

  async getConcepts(cognitoid: string) {
    return this.gateway.get<any, any>(
      'https://qs7buxp8n2.execute-api.us-west-2.amazonaws.com/production/concepts',
      {
        params: { cognitoid },
      }
    );
  }

  async getJobStatus(jobid: string) {
    return this.gateway.get<any, any>(
      'https://qs7buxp8n2.execute-api.us-west-2.amazonaws.com/production/concepts',
      {
        params: { jobid },
      }
    );
  }
}

export default ApiService;
