/* eslint-disable react/require-default-props */
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from 'react';

type ModalProps = {
  children: ReactNode;
  onClose?: () => void;
};
const Modal = ({ children, onClose }: ModalProps, ref: any) => {
  const [showModal, setShowModal] = useState(false);

  useImperativeHandle(
    ref,
    useCallback(
      () => ({
        show() {
          setShowModal(true);
        },
        hide() {
          setShowModal(false);
          onClose?.();
        },
      }),
      [onClose]
    )
  );

  const handleClose = () => {
    setShowModal(false);
    onClose?.();
  };
  return showModal ? (
    <>
      <div
        className="fixed inset-0 z-[1000] flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none"
        onClick={handleClose}
        aria-hidden="true"
      >
        <div className="flex items-center justify-center w-full rounded-lg outline-none focus:outline-none">
          <div
            className="flex-inline items-center justify-center p-6"
            onClick={(e) => {
              e.stopPropagation();
            }}
            aria-hidden="true"
          >
            {children}
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-80" />
    </>
  ) : null;
};

export default forwardRef(Modal);
