/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import StripeCheckoutForm from 'components/stripe-checkout-form';
import Layout from 'containers/layout';
import Accordion from 'components/accordion';
import Icon from 'components/icon';

const Store = () => {
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [credits, setCredits] = useState('1900');
  const paymentMethodRefs = useRef<any>({});

  const handleCreditsChange = (event) => {
    setCredits(event.target.value);
  };

  const handlePaymentMethodChange = (event: any) => {
    const { value } = event.target;
    setPaymentMethod(value);

    if (value !== 'stripe') {
      paymentMethodRefs.current.stripe.close();
    } else {
      paymentMethodRefs.current.stripe.open();
    }
  };

  return (
    <Layout>
      <div className="absolute h-[28rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:bg-headerBg md:bg-contain md:bg-right md:h-[25rem]" />
      <div className="container relative z-50">
        <section className="justify-content flex flex-col px-4 pb-5 pt-40 md:h-[35rem] md:pt-60">
          <div>
          <h1 className="mb-8 text-center text-4xl font-bold md:text-left">
	  New Premium Features coming in 2024!
      </h1>
	  We are currently revamping NeuralBlender with some unique AI features.<br/>
	  During this transition period we disabled payment allowing you to use NeuralBlender for free.
          </div>
        </section>

      </div>
    </Layout>
  );
};

export default Store;
