/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from 'components/spinner'; // Adjust this path based on your project structure
import Layout from 'containers/layout'; // Adjust this path based on your project structure
import ImageDisplay from './ImageDisplay'; // Adjust this path based on your project structure

const CreateArt: React.FC = () => {
  const [prompt, setPrompt] = useState("");
  const [blend, setBlend] = useState("nb3"); // Default to NB3
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [artworkImageUrl, setArtworkImageUrl] = useState<string | null>(null);

  const handleBlend = async () => {
    setIsSubmitting(true);
    try {
      const payload: { 
        prompt: string; 
        blend: string;
        num_inference_steps?: number;
        guidance_scale?: number;
      } = { prompt, blend };

      // Add specific parameters for mnemosyne
      if (blend === 'mnemosyne') {
        payload.num_inference_steps = 25;
        payload.guidance_scale = 7.5;
      }
      if (blend === 'nb3') {
        payload.num_inference_steps = 4;
        payload.guidance_scale = 3.5;
      }
	

      const response = await axios.post(
        'https://nb3corsproxyfunction2.azurewebsites.net/api/corsproxy/render',
        payload,
        { responseType: 'blob' } // Get data as blob for image
      );

      const imageBlob = response.data;
      const imageUrl = URL.createObjectURL(imageBlob);
      setArtworkImageUrl(imageUrl);
    } catch (err) {
      toast.error("Error creating image. Please try again.", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("Error creating image:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleBlend();
    }
  };

  return (
    <Layout>
      <div className="absolute h-[28rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:bg-headerBg md:bg-contain md:bg-right md:h-[25rem]" />
      <div className="container relative z-50">
        <section className="px-4 pb-14 pt-40 md:h-[35rem] md:px-10 md:pb-20 md:pt-60">
          <div className="text-center md:w-1/2 md:text-left">
            <h1 className="mb-5 text-4xl font-bold">Generate Art</h1>
            <p>Ask the AI anything:</p>
            <div className="mt-6 px-8 md:px-0">
              <input
                onChange={(e) => setPrompt(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="i.e. Dog walking on Mars"
                className="w-full rounded-lg bg-silver py-4 px-6 text-dark placeholder:text-shuttleGray"
              />
            </div>
            <div className="mt-6">
              <p>Select Blend:</p>
              <div className="flex items-center space-x-4 mt-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="blend"
                    value="mnemosyne"
                    checked={blend === "mnemosyne"}
                    onChange={(e) => setBlend(e.target.value)}
                    className="mr-2"
                  />
                  Mnemosyne
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="blend"
                    value="nb3"
                    checked={blend === "nb3"}
                    onChange={(e) => setBlend(e.target.value)}
                    className="mr-2"
                  />
                  Koios
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="px-8 pt-10 pb-16">
          {artworkImageUrl && (
            <div className="mt-16">
              <ImageDisplay
                imageUrl={artworkImageUrl}
                retryInterval={100}
                timeout={20000} // 20 seconds timeout
              />
            </div>
          )}

          <div className="mt-16 flex justify-center">
            <button
              className="mt-6 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-8 text-lg font-extrabold text-dark"
              type="button"
              onClick={handleBlend}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Generating...' : 'Generate Image'}
              {isSubmitting && <Spinner loading={isSubmitting} />}
            </button>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CreateArt;
