import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import ApiService from 'services/api/api';
import {
  CreateConceptParams,
  CreateConceptResponse,
  StartProceedingConceptParams,
} from 'services/api/types';

export const useCreateConcept = (
  options?: UseMutationOptions<CreateConceptResponse, unknown, CreateConceptParams>
) => useMutation((data) => ApiService.instance.createConcept(data), options);

export const useStartProceedingConcept = (
  options?: UseMutationOptions<void, unknown, StartProceedingConceptParams>
) => useMutation((data) => ApiService.instance.startProceedingConcept(data), options);
