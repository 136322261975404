import { createContext, ReactNode, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

export interface IDeviceContext {
  is2XL?: boolean;
  isXL?: boolean;
  isLG?: boolean;
  isMD?: boolean;
  isSM?: boolean;
  isXS?: boolean;
  isTablet?: boolean;
  isMobile?: boolean;
}
export const DeviceContext = createContext<IDeviceContext>(null);

interface DeviceProviderProps {
  children: ReactNode;
}
export const DeviceProvider = ({ children }: DeviceProviderProps) => {
  const is2XL = useMediaQuery({
    query: '(min-width: 1536px)',
  });
  const isXL = useMediaQuery({
    query: '(min-width: 1200px)',
  });
  const isLG = useMediaQuery({ query: '(min-width: 992px)' });
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  const isSM = useMediaQuery({ query: '(min-width: 576px)' });
  const isXS = useMediaQuery({ query: '(max-width: 576px)' });
  const isMobile = isXS || (isSM && !isMD);

  const value = useMemo(() => {
    return {
      is2XL,
      isXL,
      isLG,
      isMD,
      isSM,
      isXS,
      isTablet: isMD && !isLG,
      isMobile,
    };
  }, [is2XL, isXL, isLG, isMD, isSM, isXS, isMobile]);

  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};

export default DeviceProvider;
