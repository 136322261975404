import { useState } from 'react';
import Lottie from 'lottie-react-web';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import emailSent from 'assets/animations/envelope-mail-send.json';
import confetti from 'assets/animations/confetti.json';
import { useStore } from '../../../store/index';

const confirmEmailValidationSchema = yup.object({
  code: yup
    .string()
    .required('Verification code is required')
    .length(6, 'Verification code must have 6 characters'),
});

type ConfirmEmailProps = {
  email: string;
  onSuccess: () => void;
  closeModal: () => void;
};
const ConfirmEmail = ({ email, onSuccess, closeModal }: ConfirmEmailProps) => {
  const confirmRegistration = useStore((state) => state.confirmRegistration);
  const resendConfirmationCode = useStore((state) => state.resendConfirmationCode);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailVerified, setEmailVerify] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async ({ code }) => {
    try {
      await confirmRegistration(code);
      setEmailVerify(true);
      onSuccess?.();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const formik: any = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: confirmEmailValidationSchema,
    onSubmit: handleSubmit,
  });

  const resendCode = async () => {
    try {
      await resendConfirmationCode();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onStartBlend = () => {
    navigate('/create-art');
    closeModal();
  };

  const renderWelcomeContent = () => {
    return (
      <div className="mt-4 px-2">
        <h4 id="transition-modal-title" className="text-2xl font-semibold">
          Welcome to NeuralBlender!
        </h4>

        <div className="mt-8">
          <Lottie
            options={{
              animationData: confetti,
              loop: false,
            }}
            height="10rem"
          />
        </div>
        <div className="flex flex-1 items-center justify-center">
          <button
            type="button"
            className="rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-2 px-6 text-lg font-bold text-dark"
            onClick={onStartBlend}
          >
            start blend
          </button>
        </div>
      </div>
    );
  };

  const renderVerifyEmail = () => {
    return (
      <form onSubmit={formik.handleSubmit} onChange={() => setErrorMessage(null)}>
        <div className="mt-4 flex flex-1 flex-col px-10">
          <h4 id="transition-modal-title" className="text-2xl font-semibold">
            Verify your email
          </h4>
          <div className="mt-8 px-2 text-lg">
            <p>
              {`Almost there! We’ve sent a verification e-mail to
              ${email}.`}
            </p>
            {/* <p className="mt-3">
              You need to verify your e-mail address to log into Neural Blender.
              Click and follow the link inside it.
            </p> */}
          </div>

          <div className="flex flex-col items-center justify-center">
            <Lottie
              options={{
                animationData: emailSent,
                loop: false,
              }}
              height="10rem"
            />
            <div className="w-1/2">
              <input
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="Verification Code"
                value={formik.values.code}
                onChange={formik.handleChange('code')}
                disabled={formik.isSubmitting}
              />
            </div>
            <div className="h-5">
              <span className="text-red text-sm">{formik.touched.code && formik.errors.code}</span>
            </div>
          </div>
          <div className="mt-10 flex flex-1 flex-col items-center justify-center">
            <div className="text-red">{errorMessage}</div>
            <button
              className="mt-5 w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-bold text-dark"
              type="submit"
              disabled={formik.isSubmitting}
            >
              send{' '}
              {formik.isSubmitting && (
                <span className="ml-2">
                  <ClipLoader color="white" loading size={15} />
                </span>
              )}
            </button>
          </div>
          <div className="mt-7 text-sm">
            <span>{`Didn't get it? `}</span>
            <button
              type="button"
              className="font-bold text-link underline"
              onClick={resendCode}
              disabled={formik.isSubmitting}
            >
              Send it again!
            </button>
          </div>
        </div>
      </form>
    );
  };

  return emailVerified ? renderWelcomeContent() : renderVerifyEmail();
};

export default ConfirmEmail;
