/* eslint-disable global-require */
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useGlobalContext } from 'contexts/global-context';
import Spinner from 'components/spinner';
import Icon from 'components/icon';
import ConfirmEmail from './contents/confirm-email';
import Modal from './modal';
import { useStore } from '../../store/index';

const img3 = require('assets/images/image-3.png');
const img2 = require('assets/images/image-2.png');

const signupValidationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  // terms: yup.boolean().isTrue('Terms & Conditions is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .test('contain-lowercase', 'Password must contain lowercase characters', (value) =>
      /[a-z]/g.test(value)
    )
    .test('contain-uppercase', 'Password must have uppercase characters', (value) =>
      /[A-Z]/g.test(value)
    )
    .test('contain-symbol', 'Password must have symbol characters', (value) => /\W/g.test(value))
    .required('Password is required'),
});

type ModalSignupProps = {
  onLogin: (e: any) => void;
  toggleMenu: () => void;
};
type FormValues = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  terms: boolean;
};

const SignupModal = ({ onLogin, toggleMenu }: ModalSignupProps) => {
  const { setSignupModalRef, hideSignupModal } = useGlobalContext();
  const signUp = useStore((state) => state.signUp);
  const [showPassword, setShowPassword] = useState(false);
  const [layoutType, setLayoutType] = useState('signup');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSignup = async (values: FormValues) => {
    try {
      await signUp(values);
      setLayoutType('email_confirm');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      terms: false,
    },
    validationSchema: signupValidationSchema,
    onSubmit: handleSignup,
  });

  const handleClose = () => {
    formik.resetForm();
    hideSignupModal();
  };

  const renderSignUpForm = () => {
    return (
      <form onSubmit={formik.handleSubmit} onChange={() => setErrorMessage(null)}>
        <div className="flex flex-1 flex-col px-10">
          <h4 id="transition-modal-title" className="text-2xl font-bold md:text-4xl">
            Join the Blender
          </h4>
          <p id="transition-modal-description" className="text-lg">
            Explore the magic of AI Art
          </p>
          {/* <div className="mt-5 flex justify-center">
            <button type="button">
              <GoogleIcon className="h-[3.5rem] w-[3.5rem]" />
            </button>
            <button type="button">
              <TwitterIcon className="h-[3.5rem] w-[3.5rem]" />
            </button>
            <button type="button">
              <FBIcon className="h-[3.5rem] w-[3.5rem]" />
            </button>
            <button type="button">
              <GitIcon className="h-[3.5rem] w-[3.5rem]" />
            </button>
          </div>
          <span className="text-lightGray">or</span> */}
          <div className="mt-10">
            <div className="md:w-[20rem]">
              <input
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange('firstName')}
                disabled={formik.isSubmitting}
              />
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formik.touched.firstName && formik.errors.firstName}
                </span>
              </div>
            </div>
            <div className="md:w-[20rem] mt-1 md:mt-3">
              <input
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange('lastName')}
                disabled={formik.isSubmitting}
              />
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formik.touched.lastName && formik.errors.lastName}
                </span>
              </div>
            </div>
            <div className="md:w-[20rem] mt-1 md:mt-3">
              <input
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="E-Mail"
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                disabled={formik.isSubmitting}
              />
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formik.touched.email && formik.errors.email}
                </span>
              </div>
            </div>
            <div className="md:w-[20rem] mt-1 md:mt-3">
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange('password')}
                  disabled={formik.isSubmitting}
                  className="block w-full rounded-md px-6 py-3 bg-dark text-lg placeholder-lightGray shadow-md focus:placeholder-gray-500 focus:outline-none"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <svg
                    onClick={() => setShowPassword(false)}
                    className={`h-6 text-gray-700 ${showPassword ? 'block' : 'hidden'}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                    />
                  </svg>
                  <svg
                    onClick={() => setShowPassword(true)}
                    className={`h-6 text-gray-700 ${showPassword ? 'hidden' : 'block'}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                    />
                  </svg>
                </div>
              </div>
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formik.touched.password && formik.errors.password}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center">
            <div className="text-red">{errorMessage}</div>

            <button
              className="mt-5 w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-bold text-dark"
              type="submit"
              disabled={formik.isSubmitting}
            >
              sign up
              <Spinner loading={formik.isSubmitting} />
            </button>
          </div>
          <div className="mt-7 text-sm">
            <span>Do you already have an account? </span>
            <button
              type="button"
              className="font-bold text-link underline"
              onClick={onLogin}
              disabled={formik.isSubmitting}
            >
              Log in
            </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Modal ref={setSignupModalRef} onClose={() => formik.resetForm()}>
      <div className="relative md:max-w-5xl">
        <div className="flex min-h-[34rem] bg-dark rounded-lg border border-lightGray">
          <div className="hidden w-[40%] lg:block">
            <img
              className="h-full w-full object-cover rounded-l-lg"
              src={layoutType === 'forgot_password' ? img3 : img2}
              alt="img-1"
            />
          </div>
          <div className="flex flex-1 flex-col items-center pt-4 pb-8 text-center">
            {layoutType === 'signup' && renderSignUpForm()}
            {layoutType === 'email_confirm' && (
              <ConfirmEmail
                email={formik.values.email}
                onSuccess={toggleMenu}
                closeModal={handleClose}
              />
            )}
          </div>
        </div>
        <div className="absolute top-2 right-2">
          <button
            className="text-3xl leading-none outline-none focus:outline-none"
            onClick={handleClose}
          >
            <span className="block bg-transparent text-white outline-none focus:outline-none">
              <Icon name="close" className="h-9 w-9" />
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SignupModal;
