import SVG from 'react-inlinesvg';

import { icons } from './icons';

type IconProps = {
  name: string;
  className?: string;
  stroke?: string;
  fill?: string;
  width?: number;
  height?: number;
  opacity?: number;
};
function Icon(props: IconProps) {
  const { name, ...rest } = props;
  const IconSVG = icons[name];
  return <SVG src={IconSVG} cacheRequests {...rest} />;
}

export default Icon;
