/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import { formatDistance } from 'date-fns';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import ReactPaginate from 'react-paginate';
import ctx from 'classnames';
import { useDebounce } from 'use-debounce';

import Layout from 'containers/layout';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as LayoutTwoIcon } from 'assets/icons/layout-two.svg';
import { ReactComponent as LayoutFourIcon } from 'assets/icons/layout-four.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { useGlobalContext } from 'contexts/global-context';
import FilterDropdown from 'components/filter-dropdown';
import MobileFilter from 'components/mobile-filter';
import { escapeRegExp } from 'utils/utils';
import { useStore } from '../store/index';

const ITEMS_PER_PAGE = 100;
type ArtItemsProps = {
  currentItems: any[];
  layoutPreset: string;
};
const ArtItems = ({ currentItems, layoutPreset }: ArtItemsProps) => {
  const handleDownloadArtClick = (art) => async () => {
    try {
      await FileSaver.saveAs(
        art.info?.url,
        `${(art.info?.name || `art_${Date.now().toString()}`).replace(/\s+/g, '_')}
        .png`
      );
    } catch (e) {
      toast.error('Cannot download image. Please try again!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (currentItems.length === 0) {
    return (
      <div className="mt-20 text-center">
        <p>There is no art now</p>
      </div>
    );
  }

  return (
    <div
      className={ctx('mt-10 grid gap-y-8 gap-x-6', {
        'grid-cols-2 md:grid-cols-3': layoutPreset === 'layout-four',
      })}
    >
      {currentItems.map((art, index) => (
        <div
          key={art.job?.jobid || index}
          className={`group relative flex ${
            layoutPreset === 'layout-two' ? 'bg-mirage px-4 py-6' : 'md:w-[20rem]'
          }`}
        >
          <img
            className={`rounded-xl object-contain h-[9.5rem] w-full md:h-[20rem] ${
              layoutPreset === 'layout-two' ? 'w-[9.5rem] md:w-[20rem]' : ''
            }`}
            src={art.info.url}
            alt="art"
          />

          <div
            className={`text-xs ${
              layoutPreset === 'layout-four'
                ? 'hidden rounded-t-xl bg-white group-hover:absolute group-hover:bottom-0 group-hover:flex group-hover:w-full group-hover:text-white group-hover:bg-dark group-hover:px-5 group-hover:py-5 group-hover:opacity-95'
                : 'ml-5 md:flex md:flex-col md:flex-1'
            }`}
          >
            <div className="flex flex-1 flex-col">
              <p className="text-bold text-base">{art.info.name}</p>
              <div className="flex items-center text-lightGray">
                <ClockIcon className="mr-1 inline-block h-3 w-3" />
                <span>
                  {formatDistance(parseInt(art.info.createdAt, 10), new Date(), {
                    addSuffix: true,
                  })}
                </span>
              </div>
              <p className="mt-3">{art.info.ai}</p>
              <p>{art.info.canvasSize}</p>
              <p>{art.info.resolution}</p>
              {art.info.seed !== null && art.info.seed !== undefined && (
                <p>Seed: {art.info.seed}</p>
              )}
              <p>
                {art.info.tier === 'free' ? (
                  'Free Tier'
                ) : (
                  <>
                    Premium{' '}
                    <span className="text-link">
                      {art.info.creditsRequired ? `(${art.info.creditsRequired} CREDITS)` : ''}
                    </span>
                  </>
                )}
              </p>
            </div>
            <div
              className={`hidden md:flex md:gap-x-5 ${
                layoutPreset === 'layout-four'
                  ? 'group-hover:md:justify-between group-hover:md:flex-col-reverse group-hover:md:items-end'
                  : ''
              }`}
            >
              <div className="flex gap-x-5">
                <button onClick={handleDownloadArtClick(art)}>
                  <DownloadIcon className="w-8" />
                </button>
                {/* <button>
                  <ShareIcon className="w-8" onClick={handleShareArtClick(art)} />
                </button> */}
              </div>

              {/* <button>
                <CopyIcon className="w-8" onClick={handleCopyArtSettings(art)} />
              </button> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
const MyArt = () => {
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const getArts = useStore((state) => state.getArts);
  const jobs = useStore((state) => state.jobs);
  const getJobs = useStore((state) => state.getJobs);
  const myArtFilters = useStore((state) => state.myArtFilters);
  const updateMyArtFilters = useStore((state) => state.updateMyArtFilters);

  const [currentArts, setCurrentArts] = useState([]);
  const [layoutPreset, setLayoutPreset] = useState('layout-two');
  const [filterActive, setFilterActive] = useState(false);
  const [styles, setStyles] = useState(myArtFilters.styles);
  const [date, setDate] = useState(myArtFilters.date);
  const { showSignupModal } = useGlobalContext();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchQuery] = useDebounce(searchTerm, 500);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getJobs();
  }, []);

  const arts = useMemo(() => getArts() || [], [jobs, myArtFilters]);

  const doSearch = useCallback(() => {
    let foundArts;
    if (searchQuery?.length) {
      foundArts = arts.filter((item) => {
        const regex = new RegExp(escapeRegExp(searchTerm), 'ig');
        return regex.test(item.info.name);
      });
    } else {
      foundArts = arts;
    }
    setCurrentArts(foundArts);
    setCurrentItems(foundArts.slice(0, ITEMS_PER_PAGE) || []);
    setPageCount(Math.ceil(foundArts.length / ITEMS_PER_PAGE));
    setPage(0);
  }, [searchQuery, arts]);

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  const handleClearFilter = () => {
    setDate({
      from: null,
      to: null,
    });
    setStyles({
      hyperion: false,
      crynos: false,
      rhea: false,
      dallemega: false,
    });
    updateMyArtFilters({
      styles: {
        hyperion: false,
        crynos: false,
        rhea: false,
        dallemega: false,
      },
      date: {
        from: null,
        to: null,
      },
    });
    toast.success('Filters cleared successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleToggleFilters = () => {
    if (!filterActive) {
      setFilterActive(true);
    } else {
      setStyles(myArtFilters.styles);
      setDate(myArtFilters.date);
      setFilterActive(false);
    }
  };

  const handleApplyFilter = () => {
    updateMyArtFilters({
      styles,
      date,
    });
    setFilterActive(false);
  };

  const handleRefreshClick = async () => {
    try {
      await getJobs();
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handlePageClick = useCallback(
    ({ selected }) => {
      setPage(selected);
      setCurrentItems(
        currentArts.slice(selected * ITEMS_PER_PAGE, selected * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
      );
    },
    [currentArts]
  );

  return (
    <Layout>
      <div className={filterActive ? 'hidden' : ''}>
        <div className="absolute h-[30rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:bg-headerBg md:bg-contain md:bg-right md:h-[25rem]" />
        <div className="container md:h-[35rem] md:pt-40">
          <section className="relative z-50 px-4 pb-14 pt-40 md:px-10 md:pt-20 md:pb-20">
            <div className="text-center md:w-1/2 md:text-left">
          <h1 className="mb-8 text-4xl font-bold">Note: Please save your art until 30. November 2023. We will remove the art gallery function in NeuralBlender.</h1>
            </div>
          </section>
        </div>

        <div className="container">
          <section className="px-8 pt-8 pb-16">
            {arts.length > 2 && !isAuthenticated && (
              <div className="text-center mt-5 mb-20">
                <p className="text-center">
                  Don&apos;t lose your art. Migrate to a login-based account by signing up.
                </p>
                <button
                  className="mt-8 inline-block w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-center text-lg font-extrabold text-dark"
                  onClick={showSignupModal}
                >
                  Sign up
                </button>
              </div>
            )}
            <div className="flex">
              <div className="flex flex-1">
                <button className="mr-4" onClick={() => setLayoutPreset('layout-four')}>
                  <svg width="0" height="0">
                    <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                      <stop offset="0" />
                      <stop offset="1" />
                    </linearGradient>
                    <symbol id="border" overflow="visible">
                      <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                    </symbol>
                  </svg>
                  <div className="relative flex h-10 w-10 items-center justify-center">
                    <svg className={`module-border ${layoutPreset !== 'layout-four' && 'hidden'}`}>
                      <use href="#border" />
                    </svg>
                    <LayoutFourIcon className="h-8 w-8" />
                  </div>
                </button>
                <button className="mr-5" onClick={() => setLayoutPreset('layout-two')}>
                  <svg width="0" height="0">
                    <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                      <stop offset="0" />
                      <stop offset="1" />
                    </linearGradient>
                    <symbol id="border" overflow="visible">
                      <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                    </symbol>
                  </svg>
                  <div className="relative flex h-10 w-10 items-center justify-center">
                    <svg className={`module-border ${layoutPreset !== 'layout-two' && 'hidden'}`}>
                      <use href="#border" />
                    </svg>
                    <LayoutTwoIcon className="h-8 w-8" />
                  </div>
                </button>
                <button onClick={handleRefreshClick} className="mr-5">
                  <RefreshIcon />
                </button>
                <div className="hidden max-w-[400px] md:block w-full">
                  <div className="input-group relative flex items-stretch w-full mr-5">
                    <input
                      type="search"
                      className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-l-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="button-addon2"
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                    <button
                      className="btn inline-block px-6 py-2.5 bg-gradient-to-r from-spindle to-spray text-white font-medium text-xs leading-tight uppercase rounded-r-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                      type="button"
                      id="button-addon2"
                      onClick={doSearch}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="search"
                        className="w-4 text-black"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <button className="md:hidden" onClick={() => setFilterActive(true)}>
                <svg width="0" height="0">
                  <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                    <stop offset="0" />
                    <stop offset="1" />
                  </linearGradient>
                  <symbol id="border" overflow="visible">
                    <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                  </symbol>
                </svg>
                <div className="relative flex h-10 w-10 items-center justify-center">
                  <svg className="module-border">
                    <use href="#border" />
                  </svg>
                  <FilterIcon className="h-6 w-6" />
                </div>
              </button>
              <div className="hidden md:inline-block">
                <FilterDropdown
                  styles={styles}
                  setStyles={setStyles}
                  date={date}
                  setDate={setDate}
                  handleApplyFilter={handleApplyFilter}
                  handleClearFilter={handleClearFilter}
                />
              </div>
            </div>
            <div className="md:hidden max-w-[400px] mt-5">
              <div className="input-group relative flex items-stretch w-full mr-5">
                <input
                  type="search"
                  className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-l-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
                <button
                  className="btn inline-block px-6 py-2.5 bg-gradient-to-r from-spindle to-spray text-white font-medium text-xs leading-tight uppercase rounded-r-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                  type="button"
                  id="button-addon2"
                  onClick={doSearch}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="search"
                    className="w-4 text-black"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <ArtItems currentItems={currentItems} layoutPreset={layoutPreset} />

            {currentItems?.length > 0 && (
              <div className="mt-20">
                <ReactPaginate
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  nextLabel="Next"
                  previousLabel="Previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={undefined}
                  forcePage={page}
                />
              </div>
            )}
          </section>
        </div>
      </div>
      <MobileFilter
        open={filterActive}
        handleToggleFilters={handleToggleFilters}
        styles={styles}
        setStyles={setStyles}
        date={date}
        setDate={setDate}
        handleApplyFilter={handleApplyFilter}
        handleClearFilter={handleClearFilter}
      />
    </Layout>
  );
};

export default MyArt;
