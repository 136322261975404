export const getCanvasSize = (aspectRatio: string) => {
  if (aspectRatio === '1:1') return 'Square (1:1)';
  if (aspectRatio === '16:9') return 'Landscape (16:9)';
  if (aspectRatio === '9:16') return 'Portrait (9:16)';
  return '';
};

export const getAi = (ai: string) => {
  if (ai === 'vqganclip') return 'Hyperion Blend';
  if (ai === 'diffclip') return 'Cronos Blend';
  if (ai === 'dallemega') return 'Oceanus Blend';
  if (ai === 'phoebe') return 'Phoebe Blend';
  if (ai === 'rhea') return 'Rhea Blend';
  return '';
};

export const getResolution = ({
  aspectRatio,
  superres,
  resolution,
  width,
  height,
}: {
  aspectRatio: string;
  superres: string;
  resolution: string;
  width?: number;
  height?: number;
}) => {
  if (!aspectRatio || !superres || !resolution) return '';

  if (resolution === 'custom') {
    return superres === 'true' ? `High (${width * 4}x${height * 4})` : `Low (${width}x${height})`;
  }

  if (superres === 'true') {
    if (aspectRatio === '1:1') {
      return resolution === '256' ? 'Low (1024x1024)' : 'High (2048x2048)';
    }
    if (aspectRatio === '16:9') {
      return resolution === '256' ? 'Low (1368x760)' : 'High (2736x1536)';
    }
    if (aspectRatio === '9:16') {
      return resolution === '256' ? 'Low (760x1368)' : 'High (1536x2736)';
    }
  } else {
    if (aspectRatio === '16:9') {
      return resolution === '256' ? 'Low (342x192)' : 'High (684x384)';
    }
    if (aspectRatio === '9:16') {
      return resolution === '256' ? 'Low (192x342)' : 'High (384x684)';
    }
    return resolution === '256' ? 'Low (256x256)' : 'High (512x512)';
  }

  return '';
};

export const getArtInfo = (job) => {
  return {
    job,
    info: {
      name: job.config.prompt,
      url: `https://s3.amazonaws.com/ai.protogenes/art/${job.jobid}.png`,
      tier: job.config.tier,
      creditsRequired: job.config.creditsRequired / job.config.multiple,
      ai: getAi(job.core),
      resolution: getResolution({
        aspectRatio: job.config.aspectRatio,
        superres: job.config.superres,
        resolution: job.config.resolution,
        width: job.config.width,
        height: job.config.height,
      }),
      seed: job.config.seed,
      canvasSize: getCanvasSize(job.config.aspectRatio),
      createdAt: job.timestamp,
    },
  };
};
