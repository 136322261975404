/* eslint-disable react/button-has-type */
import { Link } from 'react-router-dom';
import Icon from 'components/icon';

const Footer = () => {
  return (
    <footer className="bg-mischka py-5 text-center md:pt-10 md:px-10">
      <div className="justify-between md:m-auto md:flex md:max-w-7xl">
        <div className="flex flex-col items-center">
          <Icon name="logo_text" className="h-16 text-dark" />
          <div className="flex justify-center">
            <a target="_blank" href="https://discord.gg/3eqCqN493J" rel="noreferrer">
              <Icon name="discord" className="h-8 fill-dark" />
            </a>
            <a
              className="mx-12"
              target="_blank"
              href="https://twitter.com/neuralblender"
              rel="noreferrer"
            >
              <Icon name="twitter" className="h-8 fill-dark" />
            </a>
            <a href="mailto:info@neuralblender.com">
              <Icon name="mail" className="h-8 fill-dark" />
            </a>
          </div>
        </div>
        <div className="hidden grid-cols-2 gap-x-20 md:grid">
          <Link to="/create-art" className="text-2xl font-extrabold text-dark">
            Create
          </Link>
          <Link to="/credits" className="text-2xl font-extrabold text-dark">
            Credits
          </Link>
          <Link to="/faq" className="text-2xl font-extrabold text-dark">
            FAQ
          </Link>
        </div>
        <div className="hidden items-start md:flex">
          <Link to="/store">
            <Icon name="stripe" />
          </Link>
        </div>
      </div>
      <div className="mt-6 md:mt-20">
        <p className="text-center text-xs text-dark md:text-lg md:font-bold">
          Copyright {new Date().getFullYear()} Neural Blender
        </p>
      </div>
    </footer>
  );
};

export default Footer;
