import { useEffect, useState } from 'react';
import find from 'lodash-es';
import { useStore } from '../store/index';

const useCurrentUser = () => {
  const getUserData = useStore((state) => state.getUserData);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    getUserData().then((user: any) => {
      const data = {
        id: find(user.UserAttributes, { name: 'sub' })?.Value,
        username: user.Username,
        email: find(user.UserAttributes, { name: 'email' })?.Value,
        email_verified: find(user.UserAttributes, { name: 'email_verified' })?.Value,
        given_name: find(user.UserAttributes, { name: 'given_name' })?.Value,
        family_name: find(user.UserAttributes, { name: 'family_name' })?.Value,
      };
      setCurrentUser(data);
    });
  }, []);

  return currentUser;
};

export default useCurrentUser;
