/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Link, useLocation, useNavigate } from 'react-router-dom';

import useCurrentUser from 'hooks/use-current-user';
import { useGlobalContext } from 'contexts/global-context';
import Icon from 'components/icon';
import { useStore } from '../store/index';

const userImage = require('assets/images/user-placeholder.png');

const NavBarItem = ({
  name,
  pathname,
  currentPathname,
  onClick,
}: {
  name: string;
  pathname: string;
  currentPathname: string;
  onClick: () => void;
}) => {
  return (
    <Link
      to={pathname}
      className={`text-4xl font-bold transition-colors hover:border-b-2 hover:border-solid hover:border-mischka md:text-2xl md:lowercase
      ${currentPathname === pathname ? 'border-b-2 border-solid border-mischka' : ''}`}
      onClick={() => {
        if (currentPathname !== pathname) {
          onClick();
        }
      }}
    >
      {name}
    </Link>
  );
};

type NavBarProps = {
  menuOpen: boolean;
  toggleMenu: (state?: boolean) => void;
};

          // <NavBarItem
          //   pathname="/queue"
          //   name="Queue"
          //   currentPathname={pathname}
          //   onClick={handleItemClick}
          // />
          // <NavBarItem
          //   pathname="/my-art"
          //   name="My Art"
          //   currentPathname={pathname}
          //   onClick={handleItemClick}
          // />


          // <NavBarItem
          //   pathname="/concept"
          //   name="Concepts"
          //   currentPathname={pathname}
          //   onClick={handleItemClick}
          // />

const NavBar = ({ menuOpen, toggleMenu }: NavBarProps) => {
  const { pathname } = useLocation();
  const balance = useStore((state) => state.balance);
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const logout = useStore((state) => state.logout);
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { showLoginModal } = useGlobalContext();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleItemClick = () => {
    toggleMenu();
  };

  return (
    <header
      className={
        menuOpen
          ? 'absolute top-0 right-0 left-0 bottom-0 z-[100] h-full min-h-[100vh] bg-header bg-cover bg-no-repeat'
          : 'absolute z-[100] w-full md:fixed md:bg-dark'
      }
    >
      <div className="absolute flex w-full items-center justify-between pl-8 pr-1 pt-5 md:hidden">
        <Link to="/">
          <Icon name="logo" />
        </Link>
        <div className="menu cross relative h-[100px] w-[100px]">
          <label className="group absolute bottom-0 block h-[100px] w-[100px] cursor-pointer">
            <input
              type="checkbox"
              className="hidden"
              onChange={() => toggleMenu()}
              checked={menuOpen}
            />
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <path className="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
              <path className="line--2" d="M0 50h70" />
              <path className="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
            </svg>
          </label>
        </div>
      </div>

      <div className="h-full md:m-auto md:flex md:max-w-[90rem] md:items-center md:py-5 md:px-10">
        <div className="hidden md:block md:mr-10 lg:mr-20">
          <Link to="/">
            <Icon name="logo_text" className="fill-mischka md:w-[7rem] lg:w-[11rem]" />
          </Link>
        </div>
        <nav
          className={`flex h-full flex-col items-center justify-between p-minHeader ${
            !menuOpen ? 'hidden' : ''
          } md:flex md:w-full md:flex-row md:p-0`}
        >
          <NavBarItem
            pathname="/create-art"
            name="Create"
            currentPathname={pathname}
            onClick={handleItemClick}
          />
          <NavBarItem
            pathname="/credits"
            name="Credits"
            currentPathname={pathname}
            onClick={handleItemClick}
          />
          <NavBarItem
            pathname="/faq"
            name="FAQ"
            currentPathname={pathname}
            onClick={handleItemClick}
          />
          {isAuthenticated ? (
            <>
              <div className="group relative hidden md:inline-block">
                <div className="flex items-center">
                  <div className="mr-4 h-12 w-12">
                    <img className="h-full w-full rounded-full" src={userImage} alt="avatar" />
                  </div>
                  <div>
                    <span className="text-lg font-extrabold">{`${currentUser?.given_name || ''} ${
                      currentUser?.family_name || ''
                    }`}</span>
                    <p className="text-link">Credits: {balance || 0}</p>
                  </div>
                </div>
                <ul className="absolute hidden pt-1 text-gray-700 group-hover:block">
                  <li>
                    <Link
                      className="whitespace-no-wrap block rounded-t bg-gray-200 py-2 px-4 hover:bg-gray-400"
                      to="/profile"
                    >
                      Profile
                    </Link>
                  </li>
                  <li>
                    <button
                      className="whitespace-no-wrap block w-full bg-gray-200 py-2 px-4 hover:bg-gray-400"
                      type="button"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>

              <div>
                <button
                  type="button"
                  className="w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-bold text-dark md:hidden"
                  onClick={handleLogout}
                >
                  logout
                </button>
                <p className="text-link text-center mt-2 md:hidden">Credits: {balance || 0}</p>
              </div>
            </>
          ) : (
            <div className="flex flex-col md:flex-row items-center gap-x-3">
              <button
                type="button"
                className="w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-bold text-dark"
                onClick={showLoginModal}
              >
                login
              </button>
              <p className="text-link mt-2 md:mt-0 md:inline-block">Credits: {balance || 0}</p>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
