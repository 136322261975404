import { isSameSecond, isAfter, isBefore } from 'date-fns';

export function renderDate(unitTimestamp: number) {
  const a = new Date(Number(unitTimestamp));
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
  const sec = a.getSeconds() < 10 ? `0${a.getSeconds()}` : a.getSeconds();
  const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`;
  return time;
}

export function sortByProperty(property) {
  return (a, b) => {
    if (a[property] > b[property]) {
      return -1;
    }
    if (a[property] < b[property]) {
      return 1;
    }

    return 0;
  };
}

export const sameOrBefore = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : !!isBefore(d1, d2);
};

export const sameOrAfter = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : !!isAfter(d1, d2);
};
export function isBetween(target, a, b) {
  return sameOrAfter(target, a) && sameOrBefore(target, b);
}
