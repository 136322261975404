/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useCallback, useContext, useRef } from 'react';

export const GlobalContext = createContext({} as any);

export const GlobalProvider = ({ children }: any) => {
  const loginModalRef = useRef<any>(null);
  const signupModalRef = useRef<any>(null);

  const setLoginModalRef = (ref) => {
    loginModalRef.current = ref;
  };

  const setSignupModalRef = (ref) => {
    signupModalRef.current = ref;
  };

  const showSignupModal = () => {
    signupModalRef.current?.show();
  };

  const hideSignupModal = () => {
    signupModalRef.current?.hide();
  };

  const showLoginModal = () => {
    loginModalRef.current?.show();
  };

  const hideLoginModal = () => {
    loginModalRef.current?.hide();
  };

  return (
    <GlobalContext.Provider
      value={{
        setLoginModalRef,
        setSignupModalRef,
        hideSignupModal,
        showSignupModal,
        showLoginModal,
        hideLoginModal,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
