import { DeviceContext } from 'contexts/responsive-context';
import { useContext } from 'react';

export const useDeviceResponsive = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDeviceResponsive must be used within a DeviceProvider');
  }
  return context;
};
