import Layout from 'containers/layout';
import { useState } from 'react';
import { useCreateConcept, useStartProceedingConcept } from 'services/react-query/mutations';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import Spinner from 'components/spinner';
import axios from 'axios';
import { format } from 'date-fns';
import orderBy from 'lodash-es/orderBy';

import useJobStatusInterval from 'hooks/use-job-status-interval';
import useConceptsInterval from 'hooks/use-concepts-interval';
import { useStore } from '../store/index';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor('jobid', {
    header: () => <span>jobid</span>,
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('time_created', {
    header: () => <span>date/time</span>,
    cell: (info) => format(parseInt(info.getValue(), 10), 'MM/dd/yyyy'),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    cell: (info) => <i>{info.getValue()}</i>,
    header: () => <span>name</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('aircore', {
    header: () => 'base model',
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    footer: (info) => info.column.id,
  }),
];

async function uploadFileToS3(presignedPostData, files, onUploadProgress) {
  const formData = new FormData();

  // append the fields in presignedPostData in formData
  Object.keys(presignedPostData.fields).forEach((key) => {
    formData.append(key, presignedPostData.fields[key]);
  });

  // append the file
  formData.append('file', files);

  // post the data on the s3 url
  return axios.post(presignedPostData.url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

const Concept = () => {
  const cognitoid = useStore((state) => state.cognitoid);
  const [isCreated, setIsCreated] = useState(false);
  const [conceptName, setConceptName] = useState('');
  const [airCore, setAirCore] = useState<string>('mnemosyne');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState<any>({});
  const [jobid, setJobid] = useState<string>();

  const { mutateAsync: createConcept, isLoading: isCreatingConcept } = useCreateConcept();

  const {
    mutateAsync: startProceedingConcept,
    isLoading: isStartProceedingConcept,
    isSuccess: isStartedProceedingConcept,
  } = useStartProceedingConcept();

  const {
    isFetching: isGettingConcepts,
    data: concepts = [],
    refetch: refetchingConcepts,
  } = useConceptsInterval(cognitoid, {
    select(d) {
      return orderBy(d, 'time_created', ['desc']).filter(
        (c) => c.status !== 'created' || c.jobid === jobid
      );
    },
  });

  const { isFetching: isProcessingConcept } = useJobStatusInterval(jobid, {
    onSuccess: (d) => {
      if (d.status === 'finished') {
        setFiles([]);
        setConceptName('');
        setJobid(undefined);
      }
    },
  });
  const table = useReactTable({
    data: concepts,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const disabled =
    isCreatingConcept ||
    isStartProceedingConcept ||
    isProcessingConcept ||
    isStartedProceedingConcept;

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    disabled,
    accept: {
      'image/*': [],
    },
    maxFiles: 30,
    onDrop: (fs) => {
      fs.map((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFiles((prevState) => [...prevState, Object.assign(file, { src: e.target.result })]);
        };
        reader.readAsDataURL(file);
        return file;
      });
    },
  });

  const thumbs = files.map((file, index) => (
    <div className="flex flex-row gap-2" key={index}>
      <div className="h-20 w-20">
        <img alt={file.name} src={file.src} className="h-full w-full" />
      </div>
      <div>
        <div>Filename: {file.name}</div>
        {uploadProgress[index] !== undefined &&
          (uploadProgress[index].error ? (
            <div>Cannot upload the file</div>
          ) : (
            <div>Upload progress: {uploadProgress[index].progress}%</div>
          ))}
        {!jobid && (
          <button
            disabled={disabled}
            className="bg-gray-50 text-black px-1 mt-2"
            onClick={() =>
              setFiles((prev) => [...files.slice(0, index), ...files.slice(index + 1)])
            }
          >
            Remove
          </button>
        )}
      </div>
    </div>
  ));

  const handleAiCoreChange = (e) => {
    const { value } = e.target;
    setAirCore(value);
  };

  const handleStartProceedingConcept = async () => {
    try {
      const response = await createConcept({
        name: conceptName,
        user: {
          cognitoid,
        },
        seed: 1337,
        learningrate: '1e-6',
        resolution: 512,
        aicore: airCore,
        trainingsteps: 2000,
        concepttype: 'dblora',
      });

      // Upload files to s3 via presigned url
      await Promise.all(
        acceptedFiles.map(async (file, index) =>
          uploadFileToS3(response, file, (event) => {
            return setUploadProgress((progress) => {
              return {
                ...progress,
                [index]: {
                  progress: Math.round((100 * event.loaded) / event.total),
                  error: false,
                },
              };
            });
          }).catch((err) => {
            setUploadProgress((progress) => {
              return {
                ...progress,
                [index]: {
                  progress: 0,
                  error: true,
                },
              };
            });
          })
        )
      );

      const regex = /finetune\/input\/(.+)\/\${filename}/;
      const match = response?.fields?.key?.match(regex);
      const id = match?.[1];
      setJobid(id);
      refetchingConcepts();

      await startProceedingConcept({
        user: {
          cognitoid,
        },
        jobid: id,
      });
      refetchingConcepts();
    } catch (err) {
      toast.error(
        err.message || 'Oops! There is an error while starting concept. Please try again later!',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleCreateClick = () => {
    const trimmedConceptName = conceptName.trim();
    if (trimmedConceptName.length === 0) {
      setErrorMessage('Concept name is required');
      return;
    }

    setIsCreated(true);
  };
// <li className="flex items-center">
//                   <input
//                     disabled={disabled}
//                     className="peer"
//                     type="radio"
//                     value="phoebe"
//                     name="phoebe"
//                     id="base_model_phoebe"
//                     checked={airCore === 'phoebe'}
//                     onChange={handleAiCoreChange}
//                   />
//                   <span className="cursor-pointer ml-2 text-lg font-medium">Phoebe</span>
//                 </li>
//                 <li className="flex items-center">
//                   <input
//                     disabled={disabled}
//                     className="peer"
//                     type="radio"
//                     value="helios"
//                     name="helios"
//                     id="base_model_helios"
//                     checked={airCore === 'helios'}
//                     onChange={handleAiCoreChange}
//                   />
//                   <span className="cursor-pointer ml-2 text-lg font-medium">Helios</span>
//                 </li>
//                 <li className="flex items-center">
//                   <input
//                     disabled={disabled}
//                     className="peer"
//                     type="radio"
//                     value="aphroodite"
//                     name="aphroodite"
//                     id="base_model_aphroodite"
//                     checked={airCore === 'aphroodite'}
//                     onChange={handleAiCoreChange}
//                   />
//                   <span className="cursor-pointer ml-2 text-lg font-medium">Aphroodite</span>
//                 </li>
  return (
    <Layout>
      <div className="container relative z-50 pt-40 pb-10">
        <div className="flex flex-col gap-10 px-10">
          <div className="flex flex-1 flex-col">
            <div className="mt-10 flex flex-col gap-5 md:flex-row md:items-center">
              <h3 className="text-xl font-semibold">Concept Name:</h3>
              <div className="flex flex-col">
                <input
                  disabled={disabled}
                  value={conceptName}
                  onChange={(e) => {
                    setErrorMessage(undefined);
                    setConceptName(e.target.value);
                  }}
                  placeholder="Concept Name"
                  className="w-[20rem] rounded-lg bg-silver py-4 px-6 text-dark placeholder:text-shuttleGray"
                />
                {!!errorMessage && <span className="text-red">{errorMessage}</span>}
              </div>
            </div>

            <div className="mt-10 flex flex-col gap-5 md:flex-row md:items-center">
              <h3 className="text-xl font-semibold">Blend:</h3>
          <ul className="flex flex-row gap-x-5">
                      <li className="flex items-center">
                  <input
                    disabled={disabled}
                    className="peer"
                    type="radio"
                    value="mnemosyne"
                    name="mnemosyne"
                    id="base_model_mnemosyne"
                    checked={airCore === 'mnemosyne'}
                    onChange={handleAiCoreChange}
                  />
                  <span className="cursor-pointer ml-2 text-lg font-medium">Mnemosyne</span>
                </li>

                
              </ul>
            </div>

            {isCreated && (
              <div className="mt-10">
                <div
                  {...getRootProps({
                    className: 'dropzone',
                  })}
                >
                  <input {...getInputProps()} />
                  <p className="text-sms">
                    Drag &apos;n&apos; drop some files here, or click to select files
                  </p>
                  <em className="text-xs">
                    (30 files are the maximum number of files you can drop here)
                  </em>
                </div>
                <aside className="mt-5">
                  <h4>Files</h4>
                  <div className="grid grid-cols-2 gap-5">{thumbs}</div>
                </aside>
              </div>
            )}

            <div className="flex gap-4">
              {!isCreated ? (
                <button
                  className="relative mt-6 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-8 text-lg font-extrabold text-dark"
                  type="button"
                  onClick={handleCreateClick}
                >
                  Create
                  <Spinner loading={isCreatingConcept} />
                </button>
              ) : (
                <button
                  disabled={disabled}
                  className="relative mt-6 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-8 text-lg font-extrabold text-dark"
                  type="button"
                  onClick={handleStartProceedingConcept}
                >
                  {isStartedProceedingConcept ? 'Creating Concept' : 'Start'}
                  <Spinner loading={isStartProceedingConcept || isStartedProceedingConcept} />
                </button>
              )}
            </div>
          </div>
          <div className="flex flex-1 flex-col">
            <h3 className="text-xl font-semibold">
              Existing concepts {isGettingConcepts ? '(Refreshing...)' : ''}
            </h3>
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Concept;
