/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */

import Accordion from 'components/accordion';
import Icon from 'components/icon';
import DatePicker from 'react-datepicker';

type MobileFilterDropdownProps = any;
const MobileFilter = ({
  open,
  styles,
  setStyles,
  date,
  setDate,
  handleApplyFilter,
  handleClearFilter,
  handleToggleFilters,
}: MobileFilterDropdownProps) => {
  return (
    <section
      className={`absolute top-0 bottom-0 left-0 right-0 z-[100] h-[100vh] w-full overflow-hidden bg-header bg-cover bg-no-repeat px-8 pt-20 ${
        !open && 'hidden'
      }`}
    >
      <button className="absolute right-5 top-10" onClick={handleToggleFilters}>
        <Icon name="close" />
      </button>
      <h3 className="mb-10 text-center text-4xl font-bold">Filter</h3>
      <Accordion
        title="Date"
        content={
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            aria-hidden="true"
          >
            <DatePicker
              selected={date?.from && typeof date?.from === 'number' ? new Date(date?.from) : null}
              onChange={(da) => setDate((d) => ({ ...d, from: da.getTime() }))}
              placeholderText="From"
              className="block w-full rounded-md px-6 py-3 bg-mischka placeholder-lightGray text-lg text-black"
            />
            <DatePicker
              selected={date?.to && typeof date?.from === 'number' ? new Date(date?.to) : null}
              onChange={(da) => setDate((d) => ({ ...d, to: da.getTime() }))}
              placeholderText="To"
              className="mt-2 block w-full rounded-md px-6 py-3 bg-mischka placeholder-lightGray text-lg text-black"
            />
          </div>
        }
      />
      <Accordion
        title="Style"
        content={
          <ul className="flex flex-col gap-y-3 ml-1">
            <li
              className="flex items-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="checkbox"
                value="crynos"
                name="styles"
                id="crynos"
                checked={styles.crynos}
                onChange={(e) => {
                  e.stopPropagation();
                  setStyles((s) => ({ ...s, crynos: !s.crynos }));
                }}
              />
              <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="crynos">
                Crynos
              </label>
            </li>
            <li
              className="flex items-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="checkbox"
                value="hyperion"
                name="styles"
                id="hyperion"
                checked={styles.hyperion}
                onChange={(e) => {
                  e.stopPropagation();
                  setStyles((s) => ({ ...s, hyperion: !s.hyperion }));
                }}
              />
              <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="hyperion">
                Hyperion
              </label>
            </li>
            <li
              className="flex items-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="checkbox"
                value="dallemega"
                name="styles"
                id="dallemega"
                checked={styles.dallemega}
                onChange={(e) => {
                  e.stopPropagation();
                  setStyles((s) => ({ ...s, dallemega: !s.dallemega }));
                }}
              />
              <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="dallemega">
                Oceanus
              </label>
            </li>
            <li
              className="flex items-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="checkbox"
                value="rhea"
                name="styles"
                id="rhea"
                checked={styles.rhea}
                onChange={(e) => {
                  e.stopPropagation();
                  setStyles((s) => ({ ...s, rhea: !s.rhea }));
                }}
              />
              <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="rhea">
                Rhea
              </label>
            </li>
          </ul>
        }
      />

      <div className="mt-20 flex items-end justify-center">
        <button
          className="mr-6 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-8 font-extrabold text-dark file:text-lg"
          onClick={handleApplyFilter}
        >
          apply
        </button>
        <button className="text-lg text-link underline" onClick={handleClearFilter}>
          clear all
        </button>
      </div>
    </section>
  );
};

export default MobileFilter;
