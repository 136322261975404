/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import DatePicker from 'react-datepicker';
import Icon from 'components/icon';
import Accordion from 'components/accordion';

type FilterDropdownProps = any;
const FilterDropdown = ({
  styles,
  setStyles,
  date,
  setDate,
  handleApplyFilter,
  handleClearFilter,
}: FilterDropdownProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button>
              <svg width="0" height="0">
                <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                  <stop offset="0" />
                  <stop offset="1" />
                </linearGradient>
                <symbol id="border" overflow="visible">
                  <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                </symbol>
              </svg>
              <div className="relative flex h-10 w-10 items-center justify-center">
                <svg className="module-border">
                  <use href="#border" />
                </svg>
                <Icon name="filter" className="h-6 w-6" />
              </div>
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="z-50 origin-top-right absolute right-0 mt-2 w-[25rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="px-4 py-4">
                <h3 className="font-bold text-2xl text-black text-center">Filters</h3>
                <Accordion
                  titleClassName="font-medium text-black"
                  title="Date"
                  content={
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      aria-hidden="true"
                    >
                      <DatePicker
                        selected={
                          date?.from && typeof date?.from === 'number' ? new Date(date?.from) : null
                        }
                        onChange={(da) => setDate((d) => ({ ...d, from: da.getTime() }))}
                        placeholderText="From"
                        className="block w-full rounded-md px-6 py-3 bg-mischka placeholder-lightGray text-lg text-black"
                      />
                      <DatePicker
                        selected={
                          date?.to && typeof date?.from === 'number' ? new Date(date?.to) : null
                        }
                        onChange={(da) => setDate((d) => ({ ...d, to: da.getTime() }))}
                        placeholderText="To"
                        className="mt-2 block w-full rounded-md px-6 py-3 bg-mischka placeholder-lightGray text-lg text-black"
                      />
                    </div>
                  }
                />
                <Accordion
                  titleClassName="font-medium text-black"
                  title="Style"
                  content={
                    <ul className="flex flex-col gap-y-3 ml-1">
                      <li
                        className="flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="checkbox"
                          value="crynos"
                          name="styles"
                          id="crynos"
                          checked={styles.crynos}
                          onChange={(e) => {
                            e.stopPropagation();
                            setStyles((s) => ({ ...s, crynos: !s.crynos }));
                          }}
                        />
                        <label
                          className="cursor-pointer ml-2 text-lg font-medium text-black"
                          htmlFor="crynos"
                        >
                          Cronos
                        </label>
                      </li>

                      <li
                        className="flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="checkbox"
                          value="hyperion"
                          name="styles"
                          id="hyperion"
                          checked={styles.hyperion}
                          onChange={(e) => {
                            e.stopPropagation();
                            setStyles((s) => ({ ...s, hyperion: !s.hyperion }));
                          }}
                        />
                        <label
                          className="cursor-pointer ml-2 text-lg font-medium text-black"
                          htmlFor="hyperion"
                        >
                          Hyperion
                        </label>
                      </li>
                      <li
                        className="flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="checkbox"
                          value="dallemega"
                          name="styles"
                          id="dallemega"
                          checked={styles.dallemega}
                          onChange={(e) => {
                            e.stopPropagation();
                            setStyles((s) => ({ ...s, dallemega: !s.dallemega }));
                          }}
                        />
                        <label
                          className="cursor-pointer ml-2 text-lg font-medium text-black"
                          htmlFor="dallemega"
                        >
                          Oceanus
                        </label>
                      </li>
                      <li
                        className="flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="checkbox"
                          value="rhea"
                          name="styles"
                          id="rhea"
                          checked={styles.rhea}
                          onChange={(e) => {
                            e.stopPropagation();
                            setStyles((s) => ({ ...s, rhea: !s.rhea }));
                          }}
                        />
                        <label
                          className="cursor-pointer ml-2 text-lg font-medium text-black"
                          htmlFor="rhea"
                        >
                          Rhea
                        </label>
                      </li>
                    </ul>
                  }
                />

                <div className="mt-10 flex items-end justify-center">
                  <Menu.Item>
                    <button
                      className="mr-6 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-8 font-extrabold text-dark file:text-lg text-lg"
                      onClick={handleApplyFilter}
                    >
                      apply
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button className="text-lg text-black underline" onClick={handleClearFilter}>
                      clear all
                    </button>
                  </Menu.Item>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default FilterDropdown;
