/* eslint-disable no-await-in-loop */
import ApiService from 'services/api/api';
import orderBy from 'lodash-es/orderBy';
import uniqBy from 'lodash-es/uniqBy';
import { endOfDay, fromUnixTime, startOfDay } from 'date-fns';
import { getArtInfo } from 'utils/data';
import { isBetween } from 'utils/time';
import { StateSlice, Store } from '../type';

type Filter = {
  styles?: {
    hyperion: boolean;
    crynos: boolean;
    rhea: boolean;
    dallemega: boolean;
  };
  date?: {
    from: number;
    to: number;
  };
};

type CreateArtParams = {
  ai: string;
  text: string;
  aspectRatio: string;
  resolution: string;
  iterations: number;
  creditsRequired: number;
  diffusionQuality: string;
  width: number;
  height: number;
  superRes: boolean;
  tier: string;
  multiple: number;
  seed: number;
  style?: string;
  concept?: {
    concepttype: string;
    concept: string;
  };
};

export type ArtSlice = {
  stack: any[];
  jobs: any[];
  jobDict: any;
  queueFilters: Filter;
  myArtFilters: Filter;
  createArt(data: CreateArtParams): Promise<any>;
  updateQueueFilters(filters: Filter): void;
  updateMyArtFilters(filters: Filter): void;
  getJobs(): Promise<void>;
  getFinishedJobs(): any[];
  getArts(): any;
  updateJobState(jobid: string, state: string): any;
};
export const createArtSlice: StateSlice<Store, ArtSlice> = (set, get) => ({
  stack: [],
  jobs: [],
  jobDict: {},
  queueFilters: {
    styles: {
      hyperion: false,
      crynos: false,
      rhea: false,
      dallemega: false,
    },
    date: {
      from: null,
      to: null,
    },
  },
  myArtFilters: {
    styles: {
      hyperion: false,
      crynos: false,
      rhea: false,
      dallemega: false,
    },
    date: {
      from: null,
      to: null,
    },
  },
  async getJobs() {
    const { cognitoid } = get();
    let resume;
    let jobs = [];
    while (resume !== null) {
      const data: any = await ApiService.instance.getJobs({
        cognitoid,
        resume,
      });
      if (data.jobs.length === 0) {
        break;
      }
      jobs.push(...(data.jobs || []));
      resume = data.last === null ? null : data.last?.jobid;
    }
    jobs = uniqBy(jobs, 'jobid');
    set((state) => {
      state.jobs = orderBy(jobs, ['timestamp'], ['desc']);
    });
  },
  getFinishedJobs() {
    return get().jobs.filter((job) => !job.jobopen);
  },
  updateJobState(jobid: string, state: string) {
    set((currentState) => {
      currentState.jobDict[jobid] = state;
      if (state === 'finished') {
        const index = currentState.jobs.findIndex((job) => job.jobid === jobid);
        if (index !== -1) {
          currentState.jobs[index].jobopen = false;
        }
      }
    });
  },
  getArts() {
    const { myArtFilters, getFinishedJobs } = get();
    return getFinishedJobs()
      .map((job) => getArtInfo(job))
      .filter((art) => {
        const { hyperion, crynos, rhea, dallemega } = myArtFilters.styles || {};
        const { from: createdFrom, to: createdTo } = myArtFilters.date || {};
        let valid = true;
        if (
          createdFrom &&
          createdTo &&
          typeof createdFrom === 'number' &&
          typeof createdTo === 'number'
        ) {
          if (art.info?.createdAt !== undefined) {
            const isBelongRange = isBetween(
              fromUnixTime(art.info.createdAt / 1000),
              startOfDay(new Date(createdFrom)),
              endOfDay(new Date(createdTo))
            );
            if (!isBelongRange) {
              valid = false;
            }
          }
        }

        if (!hyperion || !crynos || !rhea || !dallemega) {
          if (
            (hyperion && art.job?.core !== 'vqganclip') ||
            (rhea && art.job?.core !== 'rhea') ||
            (dallemega && art.job?.core !== 'dallemega') ||
            (crynos && art.job?.core !== 'diffclip')
          ) {
            valid = false;
          }
        }

        return valid;
      });
  },
  async createArt(data: CreateArtParams) {
    const { cognitoid } = get();
    const aiConfig: any = {
      core: data.ai,
      user: {
        email: '', // email null results in ast parsing error in addjob lambda func
        cognitoid: String(cognitoid),
      },
      config: {
        prompt: data.text,
        width: data.width,
        height: data.height,
        superres: String(data.superRes),
        tier: data.tier,
        multiple: data.multiple,
        aspectRatio: data.aspectRatio,
        resolution: data.resolution,
        iterations: data.iterations,
        creditsRequired: data.creditsRequired,
        diffusionQuality: data.diffusionQuality,
      },
    };

    // AI core specific settings
    if (data.ai === 'diffclip') {
      aiConfig.config.quality = data.diffusionQuality;
    } else if (data.ai === 'vqganclip') {
      aiConfig.config.iterations = data.iterations;
    } else if (data.ai === 'phoebe') {
      aiConfig.config.seed = data.seed;
    }

    // concept
    if (data.concept) {
      aiConfig.config = {
        ...aiConfig.config,
        ...data.concept,
      };
    }

    const response: any = await ApiService.instance.createArt(aiConfig);
    if (response.statusCode === 400) {
      throw new Error(response.body.error);
    }

    set((state) => {
      state.stack.push({
        jobid: response.jobid,
        queue: response.queue,
      });
    });
    return response;
  },
  updateQueueFilters(filters: Filter) {
    set({
      queueFilters: filters,
    });
  },
  updateMyArtFilters(filters: any) {
    set({
      myArtFilters: filters,
    });
  },
});
