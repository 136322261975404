import { useState } from 'react';
import { useJobStatus } from 'services/react-query/queries';

const useJobStatusInterval = (jobid: string, options) => {
  const [stop, setStop] = useState(false);
  const { isFetching, data, refetch } = useJobStatus(jobid, {
    onSuccess: (d) => {
      if (d.status === 'finished') {
        setStop(true);
      }
      options?.onSuccess(d);
    },
    onError: (error) => {
      setStop(true);
    },
    enabled: !!jobid,
    refetchInterval: stop ? false : 60 * 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });

  return { isFetching, data, refetch };
};

export default useJobStatusInterval;
