import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import ApiService from 'services/api';

export const useJobStatus = (
  jobid: string,
  options?: UseQueryOptions<any, unknown, any, string[]>
) => useQuery(['jobs', jobid], () => ApiService.instance.getJobStatus(jobid), options);

export const useConcepts = (
  cognitoid: string,
  options?: UseQueryOptions<any, unknown, any, string[]>
) => useQuery(['concepts', cognitoid], () => ApiService.instance.getConcepts(cognitoid), options);
